import { Role } from "types";

export enum ExperienceArtifacts {
  AudioVideo = "audioVideo",
  Audio = "audio",
  Video = "video",
}

export enum System {
  UserPortal = "user_portal",
  Store = "store",
}

interface DownloadSection {
  label: string;
  items: Array<{
    label: JSX.Element | string;
    link: string;
  }>;
}

type DownloadsConfig = Array<DownloadSection>;

interface FilesystemConfig {
  aws: {
    endpoint: string;
    AWSAccessKeyId: string;
    acl: "private" | "public";
    policy: string;
    signature: string;
    redirect: string;
    icons: string;
    backgrounds: string;
  };
  files: {
    templates: {
      classifier: string;
      stateMachine: {
        [ExperienceArtifacts.Audio]: string;
        [ExperienceArtifacts.Video]: string;
        [ExperienceArtifacts.AudioVideo]: string;
      };
    };
  };
}

interface CheckoutConfig {
  recaptchaKey: string;
  token: string;
  stripeApiKey: string;
}

interface BrandingConfig {
  id: string;
  helmet: {
    title: string;
    description: string;
  };
  logo: {
    alt: string;
    link: string;
  };
  companyName: string;
}

interface SessionsConfig {
  hideDataIfChannelNameStartsWith: string;
  hideMaxRenderSamplesSelection?: boolean;
  hideSingleFileDownloadButtons?: boolean;
  hideRenderedRecordedSamplesTable?: boolean;
}

interface CRMUsersConfig {
  hideMembers?: boolean;
}

type UserRolesConfig = Array<Role>;

export interface Config {
  url: string;
  registrationUrl: string;
  serverUrl: string;
  favicon: string;
  logo: string;
  downloads?: DownloadsConfig;
  filesystem?: FilesystemConfig;
  checkout?: CheckoutConfig;
  sessions?: SessionsConfig;
  branding: BrandingConfig;
  CRMUsers?: CRMUsersConfig;
  userRoles: UserRolesConfig;
  logoIcon: string;
  generateAssessmentsEnabled?: boolean;
  generateSessionReportsEnabled?: boolean;
  qeegEnabled?: boolean;
}
