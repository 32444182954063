import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest, fork, all, select } from "redux-saga/effects";

import {
  fetchCurrent,
  fetchCurrentSuccess,
  fetchCurrentError,
  fetchList,
  fetchListSuccess,
  fetchListError,
  fetchMonthlyStatisticList,
  fetchMonthlyStatisticListSuccess,
  fetchMonthlyStatisticListError,
  fetchGroupedStatisticList,
  fetchGroupedStatisticListSuccess,
  fetchGroupedStatisticListError,
  delete_,
  deleteSuccess,
  deleteError,
  needUpgrade,
} from ".";
import {
  FilteringParams,
  GroupedSessionStatistic,
  Session,
  SessionDetails,
  SessionHistoryParams,
  SessionParams,
  SessionStatistic,
  SortingParams,
} from "./types";
import { mapPaginationFields, Pagination } from "../../../types/pagination";
import { DELETE, GET, POST, Response } from "../../../utils/request";
import {
  selectListPagination,
  selectGroupedStatisticPagination,
} from "./selectors";
import { endOfDay, monthAgo } from "../../../utils/datetime";
import toast from "../../../components/Toaster";

const path = `/api/datachunks`;
export const api = {
  single: (dataChunkId: string) => `${path}/${dataChunkId}/report/get`,
  list: () => `${path}/find`,
  stats: () => `${path}/stats`,
  delete: (dataChunkId: string) => `${path}/${dataChunkId}/delete`,
};

const interval = "DAY";

function* fetchCurrentSaga(action: PayloadAction<SessionParams>) {
  try {
    const params = {
      clamping: action.payload.clamping,
      numberSamples: action.payload.numberSamples,
    };

    const response: Response<{ dataChunk: SessionDetails }> = yield GET(
      api.single(action.payload.sessionId),
      params,
      undefined,
      action.payload.token
    );

    yield put(fetchCurrentSuccess(response.data.dataChunk));
  } catch (error) {
    if (error.status === 403) {
      yield put(needUpgrade());
      yield put(
        fetchCurrentError({
          ...error,
          status: 400, // map code to another because it is not relates to expired token and already handled
        })
      );
    } else {
      yield put(fetchCurrentError(error));
    }
    toast.error(error.message);
  }
}

function* fetchListSaga(
  action: PayloadAction<{
    userId: string;
    pagination: Pagination;
  }>
) {
  try {
    const oldPagination = yield select(selectListPagination);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const payload = {
      userId: action.payload.userId,
    };

    const response: Response<{ dataChunkStatsList: Session[] }> = yield POST(
      api.list(),
      payload,
      mapPaginationFields(requestPagination)
    );

    yield put(
      fetchListSuccess({
        data: response.data.dataChunkStatsList,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
      })
    );
  } catch (error) {
    yield put(fetchListError(error));
    toast.error(error.message);
  }
}

function* fetchMonthlyStatisticListSaga(
  action: PayloadAction<SessionHistoryParams>
) {
  const params = {
    ...action.payload,
    interval: interval,
    offset: new Date().getTimezoneOffset(),
    startDateTime: monthAgo(new Date()),
    endDateTime: endOfDay(new Date()),
  };

  try {
    const response: Response<{
      dataChunkStatsList: SessionStatistic[];
    }> = yield GET(api.stats(), params);

    yield put(
      fetchMonthlyStatisticListSuccess(response.data.dataChunkStatsList)
    );
  } catch (error) {
    yield put(fetchMonthlyStatisticListError(error));
    toast.error(error.message);
  }
}

function* fetchGroupedStatisticListSaga(
  action: PayloadAction<{
    userId: string;
    pagination: Pagination;
    sorting: SortingParams;
    filtering: FilteringParams;
  }>
) {
  try {
    const oldPagination = yield select(selectGroupedStatisticPagination);

    const requestPagination = {
      ...oldPagination,
      ...action.payload.pagination,
    };

    const params = {
      ...mapPaginationFields(requestPagination),
      ...action.payload.sorting,
      ...action.payload.filtering,
      groupUser: true,
    };

    const response: Response<{
      dataChunkStatsList: GroupedSessionStatistic[];
    }> = yield GET(api.stats(), params);

    yield put(
      fetchGroupedStatisticListSuccess({
        data: response.data.dataChunkStatsList,
        pagination: {
          ...requestPagination,
          ...response.meta.pagination,
        },
        sorting: action.payload.sorting,
        filtering: action.payload.filtering,
      })
    );
  } catch (error) {
    yield put(fetchGroupedStatisticListError(error));
    toast.error(error.message);
  }
}

function* deleteSaga(action: PayloadAction<string>) {
  try {
    yield DELETE(api.delete(action.payload));

    yield put(deleteSuccess());
    toast.success("Session deleted.");
    yield put(fetchList({}));
  } catch (error) {
    yield put(deleteError(error));
    toast.error(error.message);
  }
}

function* fetchCurrentWatcher() {
  yield takeLatest(fetchCurrent.type, fetchCurrentSaga);
}

function* fetchListWatcher() {
  yield takeLatest(fetchList.type, fetchListSaga);
}

function* fetchDailyStatisticListWatcher() {
  yield takeLatest(
    fetchMonthlyStatisticList.type,
    fetchMonthlyStatisticListSaga
  );
}

function* fetchGroupedStatisticListWatcher() {
  yield takeLatest(
    fetchGroupedStatisticList.type,
    fetchGroupedStatisticListSaga
  );
}

function* deleteWatcher() {
  yield takeLatest(delete_.type, deleteSaga);
}

export function* rootWatcher() {
  yield all([
    fork(fetchCurrentWatcher),
    fork(fetchListWatcher),
    fork(fetchDailyStatisticListWatcher),
    fork(fetchGroupedStatisticListWatcher),
    fork(deleteWatcher),
  ]);
}

export default rootWatcher;
