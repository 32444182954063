import moment from "moment";
import { getDateTime } from "utils/datetime";

export function formateDataForProgressChart(data?: any) {
  let newList: any = [];

  data.map((item1: any) => {
    const channels = item1?.channels;
    const sessionCloudParameters = item1.parameters.currentSessionParameters;
    if (sessionCloudParameters) {
      // add a chart for every cloud parameter
      // check if we have global paramers
      sessionCloudParameters.map((item: any) => {
        const parameter = item.parameter;
        createProgressDataPoint(item, item1, newList, parameter, 1, item.name);
      });
    }
    if (channels) {
      channels.map((item: any) => {
        const dataList = item?.data;
        if (dataList.length > 0) {
          let value = 0;
          dataList.map((dataObj: any) => {
            if (dataObj && dataObj.length > 1) {
              const frequencyValue = dataObj[1];
              value = value + frequencyValue;
            }
          });
          const channelName = item?.channelName;
          createProgressDataPoint(
            item,
            item1,
            newList,
            value,
            dataList.length,
            channelName
          );
        }
      });
    }
  });
  //sort data by date
  newList.map((item: any) => {
    let array = item?.frequencyData;
    array.sort(
      (a: any, b: any) =>
        Date.parse(a.frequencyBand) - Date.parse(b.frequencyBand)
    );
  });
  return newList;
}
function createProgressDataPoint(
  item: any,
  item1: any,
  newList: any,
  value: any,
  dataListLength: any,
  channelName: any
) {
  let newObj: any = {};
  const date = moment(item1?.startDateTime).format("MM-DD HH:mm:ss");
  const frequencyBand = date.replace(/\s/g, "\n"); // get frequency range

  const isChannelAvailable = newList.filter(
    (o: any) => o.channelName == channelName
  ); // check if channel data is already available in list
  if (isChannelAvailable.length > 0) {
    const findChannelIndex = (e: any) => e.channelName == channelName;
    let frequencyObj: any = {};
    const channelIndex = newList.findIndex(findChannelIndex); //find available channel index

    const o: any = newList[channelIndex];
    const data = o.frequencyData;

    const findFrequencyBandIndex = (e: any) => e.frequencyBand == frequencyBand;

    const frequencyBandIndex = data.findIndex(findFrequencyBandIndex); //find available frequency range
    if (frequencyBandIndex !== -1) {
      const frequencyRange = data[frequencyBandIndex];
      if (frequencyRange) {
        const newAvg = value / dataListLength; // update frequency value
        frequencyRange.frequencyValue =
          (frequencyRange?.frequencyValue + newAvg) / 2;
        data[frequencyBandIndex] = frequencyRange;
        o.frequencyData = data;
      }
    } else {
      frequencyObj.frequencyBand = frequencyBand; //update frequency data
      frequencyObj.frequencyValue = value / dataListLength; // update frequency value
      data.push(frequencyObj);
      o.frequencyData = data;
    }
  } else {
    let frequencyObj: any = {};
    let frequencyData: any = [];
    newObj.channelName = channelName;
    frequencyObj.frequencyBand = frequencyBand;
    frequencyObj.frequencyValue = value / dataListLength;
    frequencyData.push(frequencyObj);
    newObj.frequencyData = frequencyData;
    newList.push(newObj);
  }
}
