import React, { useEffect, useState } from "react";
import { fetchValue, formateDataForQEEGGraph } from "./QEEGGraphUtils";
import {
  C3Container,
  C4Container,
  ColumnContainer,
  CZContainer,
  F3Container,
  F4Container,
  F7Container,
  F8Container,
  FP1Container,
  FP2Container,
  FZContainer,
  GraphContainer,
  GraphTitle,
  HeadImage,
  HeatMapContainer,
  MainContainer,
  O1Container,
  O2Container,
  P3Container,
  P4Container,
  PZContainer,
  QEEGText,
  ScaleColourContainer,
  ScaleContainer,
  ScaleText,
  ScaleTextContainer,
  T3Container,
  T4Container,
  T5Container,
  T6Container,
  VerticalSeperator,
  VerticalSeperatorContainer,
} from "./styles";
import h337 from "heatmap.js";
import Head from "../../../../images/Head.png";
type Props = {
  data?: any;
};
const QEEGGraph = ({ data }: Props) => {
  const [qeegList, setQEEGList] = useState([]);

  useEffect(() => {
    calculateData();
  }, []);

  const calculateData = async () => {
    const list = JSON.parse(JSON.stringify(data));
    const formattedList = await formateDataForQEEGGraph([list]);
    setQEEGList(formattedList);
    setHeatMapData();
  };
  useEffect(() => {
    if (qeegList) {
      setHeatMapData();
    }
  }, [qeegList]);

  const setHeatMapData = () => {
    qeegList.map((item: any, index: any) => {
      if (document.getElementById(`heatmap-${index}`)) {
        var heatmap = h337.create({
          container: document.getElementById(`heatmap-${index}`)!,
          gradient: {
            "0.00": "rgb(0,0,255)",
            "0.10": "rgb(0,0,255)",
            "0.15": "rgb(0,0,255)",
            "0.37": "#0df3f8",
            "0.50": "rgb(0,255,0)",
            "0.75": "rgb(255,255,0)",
            "1.00": "rgb(255,0,0)",
          },
        });

        let pointss = [
          {
            radius: 23,
            x: 32,
            y: 6,
            value: fetchValue("FP1", item?.frequencyData),
          },
          {
            radius: 23,
            x: 67,
            y: 6,
            value: fetchValue("FP2", item?.frequencyData),
          },
          {
            radius: 20,
            x: 8,
            y: 24,
            value: fetchValue("F7", item?.frequencyData), //F7
          },
          {
            radius: 16,
            x: 28,
            y: 29,
            value: fetchValue("F3", item?.frequencyData),
          },
          {
            radius: 21,
            x: 51,
            y: 27,
            value: fetchValue("FZ", item?.frequencyData),
          },
          {
            radius: 16,
            x: 73,
            y: 28,
            value: fetchValue("F4", item?.frequencyData),
          },
          {
            radius: 17,
            x: 90,
            y: 25,
            value: fetchValue("F8", item?.frequencyData), // F8
          },
          {
            radius: 20,
            x: 0,
            y: 52,
            value: fetchValue("T3", item?.frequencyData),
          },
          {
            radius: 21,
            x: 25,
            y: 52,
            value: fetchValue("C3", item?.frequencyData),
          },
          {
            radius: 20,
            x: 50,
            y: 52,
            value: fetchValue("CZ", item?.frequencyData),
          },
          {
            radius: 21,
            x: 76,
            y: 51,
            value: fetchValue("C4", item?.frequencyData),
          },
          {
            radius: 19,
            x: 100,
            y: 50,
            value: fetchValue("T4", item?.frequencyData),
          },
          {
            radius: 19,
            x: 8,
            y: 78,
            value: fetchValue("T5", item?.frequencyData), //T5
          },
          {
            radius: 15,
            x: 28,
            y: 74,
            value: fetchValue("P3", item?.frequencyData),
          },
          {
            radius: 19,
            x: 51,
            y: 75,
            value: fetchValue("PZ", item?.frequencyData),
          },
          {
            radius: 16,
            x: 73,
            y: 74,
            value: fetchValue("P4", item?.frequencyData),
          },
          {
            radius: 16,
            x: 91,
            y: 76,
            value: fetchValue("T6", item?.frequencyData), //T6
          },
          {
            radius: 22,
            x: 34,
            y: 95,
            value: fetchValue("O1", item?.frequencyData),
          },
          {
            radius: 23,
            x: 66,
            y: 95,
            value: fetchValue("O2", item?.frequencyData),
          },
        ];
        // let pointss = [
        //   {
        //     x: 30,
        //     y: 5,
        //     value: fetchValue("FP1", item?.frequencyData),
        //   },
        //   {
        //     x: 60,
        //     y: 25,
        //     value: fetchValue("FP2", item?.frequencyData),
        //   },
        //   {
        //     x: 8,
        //     y: 22,
        //     value: fetchValue("F7", item?.frequencyData),
        //   },
        //   {
        //     x: 25,
        //     y: 25,
        //     value: fetchValue("F3", item?.frequencyData),
        //   },
        //   {
        //     x: 50,
        //     y: 25,
        //     value: fetchValue("FZ", item?.frequencyData),
        //   },
        //   {
        //     x: 75,
        //     y: 25,
        //     value: fetchValue("F4", item?.frequencyData),
        //   },
        //   {
        //     x: 85,
        //     y: 22,
        //     value: fetchValue("F8", item?.frequencyData),
        //   },
        //   {
        //     x: 0,
        //     y: 50,
        //     value: fetchValue("T3", item?.frequencyData),
        //   },
        //   {
        //     x: 22,
        //     y: 50,
        //     value: fetchValue("C3", item?.frequencyData),
        //   },
        //   {
        //     x: 50,
        //     y: 50,
        //     value: fetchValue("CZ", item?.frequencyData),
        //   },
        //   {
        //     x: 75,
        //     y: 50,
        //     value: fetchValue("C4", item?.frequencyData),
        //   },
        //   {
        //     x: 100,
        //     y: 50,
        //     value: fetchValue("T4", item?.frequencyData),
        //   },
        //   {
        //     x: 8,
        //     y: 78,
        //     value: fetchValue("T5", item?.frequencyData),
        //   },
        //   {
        //     x: 25,
        //     y: 78,
        //     value: fetchValue("P3", item?.frequencyData),
        //   },
        //   {
        //     x: 50,
        //     y: 75,
        //     value: fetchValue("PZ", item?.frequencyData),
        //   },
        //   {
        //     x: 75,
        //     y: 78,
        //     value: fetchValue("P4", item?.frequencyData),
        //   },
        //   {
        //     x: 85,
        //     y: 78,
        //     value: fetchValue("T6", item?.frequencyData),
        //   },
        //   {
        //     x: 30,
        //     y: 95,
        //     value: fetchValue("O1", item?.frequencyData),
        //   },
        //   {
        //     x: 70,
        //     y: 95,
        //     value: fetchValue("O2", item?.frequencyData),
        //   },
        // ];
        heatmap.setData({
          max: item?.frequencyData.length > 0 ? item?.highFrequency : 50,
          data: pointss,
          min: 0,
          // min: item?.frequencyData.length > 0 ? item?.lowFrequency : 0,
        });
      }
    });
  };
  const getGraphTitle = (name: any) => {
    switch (name) {
      case "Delta":
        return "Delta (1.0 -4.0 Hz)";
      case "Theta":
        return "Theta (4.0 - 8.0 Hz)";
      case "Alpha":
        return "Alpha (8.0 - 12.0 Hz)";
      case "Beta":
        return "Beta (12.0 - 25.0 Hz)";
      case "High Beta":
        return "High Beta (25.0 - 30.0 Hz)";
      case "Gamma":
        return "Gamma (30.0 - 40.0 Hz)";
      case "High Gamma":
        return "High Gamma (40.0 - 50.0 Hz)";
      default:
        return "";
    }
  };
  const colors = [
    "#0a16d5",
    "#005cf1",
    "#00a8f4",
    "#0df3f8",
    "#12f298",
    "#0ef502",
    "#08f600",
    "#07f200",
    "#79ec00",
    "#f6f80a",
    "#ff9800",
    "#fe3f0a",
    "#ff0000",
  ];
  const renderScale = (item: any) => {
    return (
      <ColumnContainer>
        <ScaleContainer>
          {colors.map((item: any, index: number) => {
            return (
              <ScaleColourContainer>
                <div
                  style={{
                    flex: 1,
                    height: "5px",
                    backgroundColor: item,
                  }}
                />
                <VerticalSeperatorContainer>
                  <VerticalSeperator />
                  {index == colors.length - 1 ? <VerticalSeperator /> : <></>}
                </VerticalSeperatorContainer>
              </ScaleColourContainer>
            );
          })}
        </ScaleContainer>
        <ScaleTextContainer>
          <ScaleText>{item?.lowFrequency}</ScaleText>
          <ScaleText
            style={{
              marginLeft: "0px",
            }}
          >
            {item?.mediumFrequency}
          </ScaleText>
          <ScaleText
            style={{
              marginRight: "-4px",
            }}
          >
            {item?.highFrequency}
          </ScaleText>
        </ScaleTextContainer>
      </ColumnContainer>
    );
  };
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {qeegList.map((item: any, index: number) => {
        return (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MainContainer>
              <GraphContainer>
                <GraphTitle>{getGraphTitle(item?.channelName)}</GraphTitle>
                <HeadImage src={Head} alt={""} />
                <HeatMapContainer id={`heatmap-${index}`}>
                  <FP1Container />
                  <FP2Container />
                  {/* start second row */}

                  <F7Container />
                  <F3Container />
                  <FZContainer />
                  <F4Container />
                  <F8Container />
                  {/* end second row */}

                  {/* start third row */}
                  <T3Container />
                  <C3Container />
                  <CZContainer />
                  <C4Container />
                  <T4Container />
                  {/* end third row */}
                  {/* start fourth row */}
                  <T5Container />
                  <P3Container />
                  <PZContainer />
                  <P4Container />
                  <T6Container />
                  {/* end fourth row */}
                  <O1Container />
                  <O2Container />
                </HeatMapContainer>
              </GraphContainer>
              {/* <QEEGText>QEEG</QEEGText>
            <DateText>November 08, 2022 (start of neurofeedback therapy)</DateText>
            <DateText>December 9, 2022</DateText>
       */}
            </MainContainer>
            {renderScale(item)}
          </div>
        );
      })}
    </div>
  );
};

export default QEEGGraph;
