import { Config } from "../../types";
import {} from "./components";
import { messages } from "../../messages";
import { Role } from "../../../types";

const config: Config = {
  url: "https://neurofeedback.natus-rad.com",
  serverUrl: "https://backend.neurofeedback.natus-rad.com",
  registrationUrl: "",
  downloads: [],
  branding: {
    id: "natus",
    helmet: {
      title: "neurofeedback User Portal",
      description: "natus neurofeedback account management",
    },
    logo: {
      alt: "natus logo",
      link: "https://natus-rad.com",
    },
    companyName: "natus",
  },
  sessions: {
    hideDataIfChannelNameStartsWith: "",
    hideMaxRenderSamplesSelection: false,
    hideSingleFileDownloadButtons: false,
    hideRenderedRecordedSamplesTable: false,
  },
  CRMUsers: {
    hideMembers: false,
  },
  userRoles: [
    Role.ClinicAdmin,
    Role.ClinicClinician,
    Role.ClinicOperator,
    Role.ClinicPatient,
  ],
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
  logoIcon: require("./assets/logo_icon.png"),
  qeegEnabled: true,
};

export default config;
