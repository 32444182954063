import { saveAs } from "file-saver";
import { SessionDetails } from "../../store/ducks/sessions/types";
import config from "config";
import { TimeLineDataItem } from "./types";
import { delay } from "../../utils/helpers";

export const getSessionHistoryParameters = (session: SessionDetails | null) => {
  if (!session || !session.parameters) {
    return;
  }

  let table: any = {};

  if (session.parameters.previousSessionParameters) {
    session.parameters.previousSessionParameters.forEach((parameter) => {
      if (!table[parameter.name]) {
        table[parameter.name] = {};
      }

      table[parameter.name]["previous"] = parameter.parameter;
    });
  }

  if (session.parameters.currentSessionParameters) {
    session.parameters.currentSessionParameters.forEach((parameter) => {
      if (!table[parameter.name]) {
        table[parameter.name] = {};
      }

      table[parameter.name]["current"] = parameter.parameter;
    });
  }

  return Object.keys(table).map((key) => ({
    key: key,
    previous: table[key]["previous"],
    current: table[key]["current"],
  }));
};

export const getTimeLineData = (session: SessionDetails | null) => {
  if (!session || !session.channels) {
    return null;
  }

  return session.channels.reduce<Array<TimeLineDataItem>>((out, channel) => {
    if (!channel.data || !channel.data.length) {
      return out;
    }

    const prefix = config.sessions?.hideDataIfChannelNameStartsWith;
    if (prefix && channel.channelName.startsWith(prefix)) {
      return out;
    }

    const result: TimeLineDataItem = {
      data: [],
      labels: [],
      name: channel.channelName,
      dataUrl: channel.dataUrl,
      dataSamples: channel.dataSamples,
      totalSamples: channel.totalSamples,
      sampleRate: `${channel.sampleRate} Hz`,
      dataSampleRate: channel.dataSampleRate
        ? `${channel.dataSampleRate.toFixed(2).replace(".00", "")} Hz`
        : `"0.00 Hz`,
    };

    channel.data.forEach((item, i) => {
      result.data.push(Number.parseFloat(item[1].toFixed(3)));
      result.labels.push(
        channel.data && i % Math.round(channel.data.length / 20) === 0
          ? item[0].toFixed(1)
          : ""
      );
    });

    out.push(result);
    return out;
  }, []);
};

export const download = async (url: string) => {
  const a = document.createElement("a");
  a.download = "";
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();

  await delay(100);
  a.remove();
};

export const downloadReport = (session: SessionDetails | null) => {
  if (!session) return;

  const blob = new Blob([JSON.stringify(session, undefined, 2)], {
    type: "application/json;charset=utf-8",
  });

  let filename = "user-session-raw-data";
  if (session.userId) filename += `-${session.userId}`;

  saveAs(blob, `${filename}.json`);
};

export const downloadReportAndRAWChannelsData = async (
  session: SessionDetails | null
) => {
  if (!session) return;

  for (let i = 0; i < session.channels.length; i++) {
    const channel = session.channels[i];
    if (!channel.dataUrl) continue;

    await delay(i * 1000);
    download(channel.dataUrl);
  }

  downloadReport(session);
};

export const getFrequencyName = (key: string) => {
  switch (key) {
    case "38-48":
      return {
        name: "Gamma",
        pos: 0,
      };
    case "28-38":
      return {
        name: "High Beta",
        pos: 1,
      };
    case "23-38":
      return {
        name: "High Beta",
        pos: 1,
      };
    case "15-23":
      return {
        name: "Beta",
        pos: 2,
      };
    case "12-15":
      return {
        name: "SMR",
        pos: 3,
      };
    case "8-12":
      return {
        name: "Alpha",
        pos: 4,
      };
    case "3-8":
      return {
        name: "Theta",
        pos: 5,
      };
    case "1-3":
      return {
        name: "Delta",
        pos: 6,
      };
    case "0-1":
      return {
        name: "Low Delta",
        pos: 7,
      };
    default:
      return "";
  }
};

// header in csv table
export const Headers = [
  { label: "", key: "rowName" },
  { label: "F3", key: "F3" },
  { label: "F3%", key: "F3P" },
  { label: "FZ", key: "FZ" },
  { label: "FZ%", key: "FZP" },
  { label: "F4", key: "F4" },
  { label: "F4%", key: "F4P" },
  { label: "T3", key: "T3" },
  { label: "T3%", key: "T3P" },
  { label: "C3", key: "C3" },
  { label: "C3%", key: "C3P" },
  { label: "C4", key: "C4" },
  { label: "C4%", key: "C4P" },
  { label: "T4", key: "T4" },
  { label: "T4%", key: "T4P" },
  { label: "O1", key: "O1" },
  { label: "O1%", key: "O1P" },
  { label: "P3", key: "P3" },
  { label: "P3%", key: "P3P" },
  { label: "OZ", key: "OZ" },
  { label: "OZ%", key: "OZP" },
  { label: "P4", key: "P4" },
  { label: "P4%", key: "P4P" },
  { label: "O2", key: "O2" },
  { label: "O2%", key: "O2P" },
];
//frequency name use in csv table
export let frequencyName = [
  "Gamma",
  "High Beta",
  "Beta",
  "SMR",
  "Alpha",
  "Theta",
  "Delta",
  "Low Delta",
];
//csv sample data
export const sample: any = frequencyName.map((item: any) => {
  let obj = {
    rowName: item,
    F3: "-",
    F3P: "-",
    FZ: "-",
    FZP: "-",
    F4: "-",
    F4P: "-",
    T3: "-",
    T3P: "-",
    C3: "-",
    C3P: "-",
    C4: "-",
    C4P: "-",
    T4: "-",
    T4P: "-",
    O1: "-",
    O1P: "-",
    P3: "-",
    P3P: "-",
    OZ: "-",
    OZP: "-",
    P4: "-",
    P4P: "-",
    O2: "-",
    O2P: "-",
  };
  return obj;
});
