import React, { useEffect } from "react";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/ducks/auth/selectors";
import {
  selectCurrent as selectRule,
  selectIsCurrentLoading as selectRuleIsLoading,
} from "../../store/ducks/rule/selectors";
import {
  selectCurrent,
  selectIsCurrentLoading as selectIsUserLoading,
  selectIsListLoading as selectIsUserListLoading,
  selectIsChildrenLoading as selectIsUserChildrenLoading,
} from "../../store/ducks/users/selectors";
import { selectIsListLoading as selectIsGroupListLoading } from "../../store/ducks/groups/selectors";
import { selectIsListLoading as selectIsCompaniesListLoading } from "../../store/ducks/companies/selectors";
import { fetchCurrent } from "../../store/ducks/users";
import { FieldSetting, prepareFields } from "../../utils/form";
import labels from "../../utils/labels";

import Loader from "../../components/Loader";
import Tabs from "../../components/Tabs";
import AccountTab from "./AccountTab";
import ProfileTab from "./ProfileTab";
import Header from "../../components/Header";
import PersonIcon from "@material-ui/icons/Person";
import { useParams } from "react-router-dom";
import { User } from "../../store/ducks/users/types";
import { hasRule } from "../../store/ducks/auth/utils";
import { RULES } from "../../store/ducks/rule/types";
import AuthWrapper from "../../components/MainLayout/styled/AuthWrapper";

export type UpdateUserData = Omit<User, "role"> & {
  appendChildIds: string[];
  removeChildIds: string[];
  role?: number;
};

const Profile = () => {
  const dispatch = useAppDispatch();

  const auth = useSelector(selectUser)!; // auth must be already filled up because it is AuthenticatedRoute
  const user = useSelector(selectCurrent);
  const rule = useSelector(selectRule)!; // rule must be already filled up because it is AuthenticatedRoute

  const { id } = useParams<{ id?: string }>();
  const userId = id || auth.userId;

  useEffect(() => {
    dispatch(fetchCurrent(userId));
  }, [dispatch, userId]);

  const fields: Record<string, FieldSetting> = prepareFields(
    {
      userId: {},
      firstname: {},
      middlename: {},
      lastname: {},
      email: {},
      password: {},
      confirmed: {},
      gender: {},
      birthday: {},
      street: {},
      zip: {},
      state: {},
      country: {},
      city: {},
      phone: {},
      fax: {},
      website: {},
      handedness: {
        disabled: false,
      },
      reasonForTherapy: {
        disabled: false,
      },
      role: {
        required: true,
        disabled: false,
      },
      removeChildIds: {},
    },
    rule,
    labels.profile
  );

  const isUserLoading = useSelector(selectIsUserLoading);
  const isUserChildrenLoading = useSelector(selectIsUserChildrenLoading);
  const isRuleLoading = useSelector(selectRuleIsLoading);
  const isUserListLoading = useSelector(selectIsUserListLoading);
  const isGroupListLoading = useSelector(selectIsGroupListLoading);
  const isCompaniesListLoading = useSelector(selectIsCompaniesListLoading);
  const isProfileLoading =
    isUserLoading ||
    isUserChildrenLoading ||
    isRuleLoading ||
    isUserListLoading ||
    isGroupListLoading ||
    isCompaniesListLoading;

  const showUpdateButton = hasRule(auth, RULES.REST_updateUser);

  const showMembers =
    fields.removeChildIds.required || !fields.removeChildIds.disabled;
  return (
    <Loader isLoading={isProfileLoading} text={labels.loading.user}>
      <AuthWrapper>
        {user && (
          <Header level={2} icon={<PersonIcon />}>{`${user.firstname || ""} ${
            user.lastname || ""
          }`}</Header>
        )}
        <Tabs
          tabs={[
            {
              label: labels.profile.profile,
              content: (
                <ProfileTab
                  user={user!}
                  fields={fields}
                  auth={auth}
                  showUpdateButton={showUpdateButton}
                  showMembers={showMembers}
                />
              ),
            },
            {
              label: labels.profile.account,
              content: (
                <AccountTab
                  user={user!}
                  fields={fields}
                  auth={auth}
                  showUpdateButton={showUpdateButton}
                />
              ),
            },
          ]}
        />
      </AuthWrapper>
    </Loader>
  );
};

export default Profile;
