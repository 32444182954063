import React, { useEffect, useState } from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import sigma_symbol from "../../../../images/sigma_symbol.png";
import config from "config";
import { convertMilliSecondsToTimeString, getDateTime } from "utils/datetime";
import styles from "./styles";
import sessionStyle from "../GenerateSessionReport/styles";

type Props = {
  data?: any;
  userName?: string;
  date?: any;
  sessionList?: any;
};

const frequencyName = [
  { name: "Gamma", color: "#000" },
  { name: "High Beta", color: "#460DF5" },
  { name: "Beta", color: "#EB4857" },
  { name: "SMR", color: "#8CFC69" },
  { name: "Alpha", color: "#61CBCB" },
  { name: "Theta", color: "#F37BFB" },
  { name: "Delta", color: "#000" },
  { name: "Low Delta", color: "#000" },
];
const frequencyRange = [
  { name: "38-48 Hz", color: "#000" },
  { name: "28-38 Hz", color: "#460DF5" },
  { name: "15-23 Hz", color: "#EB4857" },
  { name: "12-15 Hz", color: "#8CFC69" },
  { name: "8-12 Hz", color: "#61CBCB" },
  { name: "3-8 Hz", color: "#F37BFB" },
  { name: "1-3 Hz", color: "#000" },
  { name: "0-1 Hz", color: "#000" },
];

const AssessmentImageGenerate = ({
  data,
  userName,
  date,
  sessionList,
}: Props) => {
  const [frequencyList, setFrequencyList] = useState<any>(data);
  Font.register({
    family: "Roboto",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
  });
  useEffect(() => {}, []);

  const renderValue = (
    frequency: any,
    totalValue: any,
    colour: any,
    fontSize?: any,
    isSeperator?: any,
    isIntendedText?: any
  ) => {
    return (
      <View style={styles.valueContainer}>
        <View style={{ ...styles.frequencyContainer, position: "relative" }}>
          <Text
            style={{
              ...styles.frequencyText,
              ...(colour && {
                color: colour,
                right: "8px",
                position: "absolute",
              }),
              ...(fontSize && { fontSize: fontSize }),
            }}
          >
            {frequency}
          </Text>
        </View>
        {isSeperator && <View style={styles.dashedLine}></View>}
        <View style={{ ...styles.frequencyContainer }}>
          <Text
            style={{
              ...styles.frequencyText,
              ...(colour && { color: colour }),
              ...(isIntendedText && { marginLeft: "-10px" }),
              ...(fontSize && { fontSize: fontSize }),
            }}
          >
            {frequency !== "-" && totalValue
              ? ((frequency * 100) / totalValue).toFixed(2) + "%"
              : "-"}
          </Text>
        </View>
      </View>
    );
  };
  const getFrequencyValue = (frequencyData: any, frequencyRange: any) => {
    const frequencyRangeList = frequencyData.filter(
      (o: any) => o?.frequencyBand == frequencyRange
    );
    if (frequencyRangeList && frequencyRangeList.length > 0) {
      return (frequencyRangeList[0]?.frequencyValue).toFixed(2) + "";
    }
    return "-";
  };
  const renderBand = (channelName: any, channelData?: any) => {
    const frequencyData = channelData?.frequencyData || [];

    let TotalFrequency = 0;
    frequencyData.map((i: any) => {
      TotalFrequency =
        parseFloat(TotalFrequency.toFixed(2)) + i?.frequencyValue;
    });
    return (
      <View style={styles.itemContainer}>
        <View style={styles.header}>
          <Text style={styles.headerText}>{`${channelName} (`}</Text>
          <Image
            src={sigma_symbol}
            style={{
              width: "10px",
              height: "10px",
            }}
          ></Image>
          <Text
            style={{ ...styles.headerText, marginLeft: "1px" }}
          >{`: ${TotalFrequency.toFixed(2)})`}</Text>
        </View>
        {renderValue(
          getFrequencyValue(frequencyData, "38-48"),
          TotalFrequency,
          "#000000"
        )}
        {renderValue(
          getFrequencyValue(frequencyData, "23-38"),
          TotalFrequency,
          "#460DF5"
        )}
        {renderValue(
          getFrequencyValue(frequencyData, "15-23"),
          TotalFrequency,
          "#EB4857"
        )}
        {renderValue(
          getFrequencyValue(frequencyData, "12-15"),
          TotalFrequency,
          "#8CFC69"
        )}
        <View style={styles.horizontalSeperator}></View>
        {renderValue(
          getFrequencyValue(frequencyData, "8-12"),
          TotalFrequency,
          "#61CBCB",
          "",
          true
        )}
        <View style={styles.horizontalSeperator}></View>
        {renderValue(
          getFrequencyValue(frequencyData, "3-8"),
          TotalFrequency,
          "#F37BFB",
          "",
          true
        )}
        <View style={styles.horizontalSeperator} />
        {renderValue(
          getFrequencyValue(frequencyData, "1-3"),
          TotalFrequency,
          "#000000"
        )}
        {renderValue(
          getFrequencyValue(frequencyData, "0-1"),
          TotalFrequency,
          "#000000"
        )}
        <View style={styles.seperator} />
      </View>
    );
  };
  const findData = (channelName: any) => {
    const filter = frequencyList.filter(
      (o: any) => o.channelName === channelName
    );
    if (filter && filter.length > 0) {
      return filter[0];
    } else {
    }
  };
  const renderLeftFrequencyBand = () => {
    return (
      <View style={styles.leftContainer}>
        {renderBand("T3", findData("T3"))}
        <View style={styles.marginTop}>{renderBand("O1", findData("O1"))}</View>
      </View>
    );
  };

  const renderRightFrequencyBand = () => {
    return (
      <View style={styles.rightContainer}>
        {renderBand("T4", findData("T4"))}
        <View style={styles.marginTop}>{renderBand("O2", findData("O2"))}</View>
      </View>
    );
  };

  const renderCenterFrequencyBand = () => {
    return (
      <View style={styles.centerContainer}>
        <View>
          {renderBand("F3", findData("F3"))}
          <View style={styles.marginTop16}>
            {renderBand("C3", findData("C3"))}
          </View>
          <View style={styles.marginTop16}>
            {renderBand("P3", findData("P3"))}
          </View>
        </View>
        <View>
          {renderBand("FZ", findData("FZ"))}
          <View style={styles.marginTop174}>
            {renderBand("OZ", findData("OZ"))}
          </View>
        </View>
        <View>
          {renderBand("F4", findData("F4"))}
          <View style={styles.marginTop16}>
            {renderBand("C4", findData("C4"))}
          </View>
          <View style={styles.marginTop16}>
            {renderBand("P4", findData("P4"))}
          </View>
        </View>
      </View>
    );
  };
  const renderSessionList = (list: any) => {
    return (
      <View
        style={{
          ...sessionStyle.userDetailBorder,
          marginTop: "10px",
          border: "1px solid #CFD3DA",
          marginRight: "12px",
        }}
      >
        <View style={sessionStyle.tableHeaderContainer}>
          <View style={{ ...sessionStyle.cellContainer, flex: 1.5 }}>
            <Text
              style={{
                ...sessionStyle.headerText,
                fontSize: "10px",
                marginLeft: "10px",
                textAlign: "left",
              }}
            >
              EXERCISE NAME
            </Text>
          </View>
          <View style={sessionStyle.cellContainer}>
            <Text
              style={{
                ...sessionStyle.headerText,
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              TIME
            </Text>
          </View>
          <View style={sessionStyle.cellContainer}>
            <Text
              style={{
                ...sessionStyle.headerText,
                fontSize: "10px",
                textAlign: "left",
              }}
            >
              DURATION
            </Text>
          </View>
        </View>
        {renderSessionRow(list)}
      </View>
    );
  };
  const renderSessionRow = (list: any) => {
    return list.map((item: any) => {
      return (
        <View
          style={{
            width: "100%",
          }}
        >
          <View
            style={{
              ...sessionStyle.horizontalSeperator,
              backgroundColor: "#CFD3DA",
            }}
          />
          <View style={sessionStyle.tableHeaderContainer}>
            <View style={{ ...sessionStyle.cellContainer, flex: 1.5 }}>
              <Text
                style={{
                  ...sessionStyle.rowText,
                  marginLeft: "10px",
                }}
              >
                {item?.sessionName || "-"}
              </Text>
            </View>
            <View style={sessionStyle.cellContainer}>
              <Text style={sessionStyle.rowText}>
                {getDateTime(item.sessionTime)}
              </Text>
            </View>
            <View style={sessionStyle.cellContainer}>
              <Text style={sessionStyle.rowText}>
                {convertMilliSecondsToTimeString(item?.duration)}
              </Text>
            </View>
          </View>
        </View>
      );
    });
  };
  return (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={styles.page}>
          <View
            style={{
              width: "92.5%",
              marginTop: "30px",
            }}
          >
            <View style={styles.dateContainer}>
              <View>
                <Text
                  style={styles.userName}
                >{`Neuro-Assessment for ${userName}`}</Text>
                <Text style={styles.date}>{`Date: ${date}`}</Text>
              </View>
              <Image
                src={config.logoIcon}
                style={{
                  width: "40px",
                  height: "40px",
                  // marginTop: "-20px",
                }}
              />
            </View>
          </View>
          <View
            style={{
              width: "92.5%",
              flexDirection: "row",
              // alignItems: "flex-end",
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              {renderSessionList(sessionList)}
            </View>
            <View
              style={{
                width: "128px",
              }}
            >
              <View style={styles.frequencyMainContainer}>
                <View style={styles.frequencyInnerContainer}>
                  <View style={styles.frequencyC}>
                    {frequencyName.map((item, index) => {
                      return (
                        <Text
                          key={index + ""}
                          style={{ ...styles.frequency, color: item?.color }}
                        >
                          {item?.name}
                        </Text>
                      );
                    })}
                  </View>
                  <View style={{ ...styles.frequencyC, flex: 0.73 }}>
                    {frequencyRange.map((item, index) => {
                      return (
                        <Text
                          key={index + ""}
                          style={{ ...styles.frequency, color: item?.color }}
                        >
                          {item?.name}
                        </Text>
                      );
                    })}
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <View style={styles.pageContainer}>
              {/* // T3 and O1 */}
              {renderLeftFrequencyBand()}

              {/* center F3 Fz F4 */}
              {renderCenterFrequencyBand()}

              {/* T4 and O2 */}
              {renderRightFrequencyBand()}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default AssessmentImageGenerate;
