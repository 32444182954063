import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  /* padding: 20px; */
  background: #ffffff;
  flex-direction: "row";
  /* margin-right: 17px; */
  /* box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05); */
  /* border-radius: 30px; */
`;

export const QEEGText = styled.p`
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-style: normal;
  color: #000000;
  text-align: left;
  margin: auto;
`;

export const DateText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 12px;
  margin: auto;
  text-align: left;
`;
export const GraphTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 600;
  /* margin: auto; */
  text-align: center;
`;
export const GraphContainer = styled.div`
  margin-right: 15px;
  margin-left: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const ScaleText = styled.p`
  font-style: normal;
  font-size: 8px;
  color: #000000;
  font-weight: 600;
  margin-left: -4px;
`;
export const ColumnContainer = styled.div`
  flex-direction: column;
  display: flex;
`;
export const ScaleTextContainer = styled.div`
  width: 90px;
  justify-content: space-between;
  display: flex;
`;
export const ScaleContainer = styled.div`
  width: 90px;
  height: 7px;
  margin-top: 6px;
  flex-direction: row;
  display: flex;
`;
export const VerticalSeperator = styled.div`
  width: 1px;
  height: 2px;
  background-color: #000;
`;

export const VerticalSeperatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ScaleColourContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`;
export const HeadImage = styled.img`
  width: 100px;
  height: 109px;
  z-index: 4;
  margin-top: 22px;
  position: absolute;
`;
export const HeatMapContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
`;
export const FP1Container = styled.div`
  position: absolute;
  top: 5px;
  left: 30px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const FP2Container = styled.div`
  position: absolute;
  top: 5px;
  right: 30px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const F7Container = styled.div`
  position: absolute;
  top: 22px;
  left: 8px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const F3Container = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const FZContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 50px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const F4Container = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const F8Container = styled.div`
  position: absolute;
  top: 22px;
  right: 8px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const T3Container = styled.div`
  position: absolute;
  top: 50px;
  left: 0px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const C3Container = styled.div`
  position: absolute;
  top: 50px;
  left: 22px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const CZContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 50px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const C4Container = styled.div`
  position: absolute;
  top: 50px;
  right: 22px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const T4Container = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const T5Container = styled.div`
  position: absolute;
  bottom: 22px;
  left: 8px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const P3Container = styled.div`
  position: absolute;
  bottom: 22px;
  left: 25px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const PZContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;

export const P4Container = styled.div`
  position: absolute;
  bottom: 22px;
  right: 25px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const T6Container = styled.div`
  position: absolute;
  bottom: 22px;
  right: 8px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;

export const O1Container = styled.div`
  position: absolute;
  bottom: 5px;
  left: 30px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
export const O2Container = styled.div`
  position: absolute;
  bottom: 5px;
  right: 30px;
  border: 1px solid #000;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  z-index: 99;
`;
