export function formateDataForQEEGGraph(data?: any) {
  let newList: any = [];
  let channelList: any = [];

  // aggregate multiple channel list
  data.map((item: any) => {
    const channels = item?.channels;
    if (channels) {
      channelList.push(...item?.channels);
    }
  });
  channelList.map((item: any) => {
    const dataList = item?.data;
    if (dataList.length > 0) {
      let value = 0;
      dataList.map((dataObj: any) => {
        if (dataObj && dataObj.length > 1) {
          const frequencyValue = dataObj[1];
          value = value + frequencyValue;
        }
      });
      let newObj: any = {};
      const channelName = item?.channelName;
      var replaced = channelName.replace(/\s/g, ""); // replace extra space
      const channelNameArray = replaced.split("-"); // split string with - character

      if (channelNameArray && channelNameArray.length >= 2) {
        const channelName = channelNameArray[1]; // get channel name
        const frequencyBand = channelNameArray[0]; // get frequency range
        const isChannelAvailable = newList.filter(
          (o: any) => o.channelName == channelName
        ); // check if channel data is already available in list
        if (isChannelAvailable.length > 0) {
          const findChannelIndex = (e: any) => e.channelName == channelName;
          let frequencyObj: any = {};
          const channelIndex = newList.findIndex(findChannelIndex); //find available channel index

          const o: any = newList[channelIndex];
          const data = o.frequencyData;

          const findFrequencyBandIndex = (e: any) =>
            e.frequencyBand == frequencyBand;

          const frequencyBandIndex = data.findIndex(findFrequencyBandIndex); //find available frequency range
          if (frequencyBandIndex !== -1) {
            const frequencyRange = data[frequencyBandIndex];
            if (frequencyRange) {
              const newAvg = value / dataList.length; // update frequency value
              frequencyRange.frequencyValue =
                (frequencyRange?.frequencyValue + newAvg) / 2;
              data[frequencyBandIndex] = frequencyRange;
              o.frequencyData = data;
            }
          } else {
            frequencyObj.frequencyBand = frequencyBand; //update frequency data
            frequencyObj.frequencyValue = value / dataList.length; // update frequency value
            data.push(frequencyObj);
            o.frequencyData = data;
          }
        } else {
          let frequencyObj: any = {};
          let frequencyData: any = [];
          newObj.channelName = channelName;
          frequencyObj.frequencyBand = frequencyBand;
          frequencyObj.frequencyValue = value / dataList.length;
          frequencyData.push(frequencyObj);
          newObj.frequencyData = frequencyData;
          newList.push(newObj);
        }
      }
    }
  });
  // const requireList = ['Delta', 'Theta', 'Alpha', 'Beta', 'High Beta', 'Gamma', 'High Gamma']
  const requireList = [
    {
      channelName: "Delta",
      lowFrequency: "4.0",
      mediumFrequency: "12.5",
      highFrequency: "21.0",
    },
    {
      channelName: "Theta",
      lowFrequency: "3.2",
      mediumFrequency: "6.7",
      highFrequency: "10.2",
    },
    {
      channelName: "Alpha",
      lowFrequency: "7.0",
      mediumFrequency: "25.0",
      highFrequency: "43.0",
    },
    {
      channelName: "Beta",
      lowFrequency: "4.1",
      mediumFrequency: "8.2",
      highFrequency: "12.3",
    },
    {
      channelName: "High Beta",
      lowFrequency: "0.40",
      mediumFrequency: "0.88",
      highFrequency: "1.33",
    },
    {
      channelName: "Gamma",
      lowFrequency: "0.4",
      mediumFrequency: "1.4",
      highFrequency: "2.4",
    },
    {
      channelName: "High Gamma",
      lowFrequency: "0.3",
      mediumFrequency: "1.4",
      highFrequency: "2.5",
    },
  ];

  let filterList: any = [];
  requireList.map((item) => {
    const isChannelAvailable = newList.filter((o: any) =>
      item?.channelName == "High Beta"
        ? o?.channelName == item?.channelName || o?.channelName == "Hi Beta"
        : o?.channelName == item?.channelName
    );
    if (isChannelAvailable.length > 0) {
      const findChannelIndex = (e: any) =>
        item?.channelName == "High Beta"
          ? e.channelName == item?.channelName || e?.channelName == "Hi Beta"
          : e.channelName == item?.channelName;

      const channelIndex = newList.findIndex(findChannelIndex);
      console.log(channelIndex, item?.channelName);
      let obj = {
        ...newList[channelIndex],
        ...item,
        channelName:
          item?.channelName == "Hi Beta" ? "High Beta" : item?.channelName,
      };
      filterList.push(obj);
    } else {
      filterList.push({
        // channelName: item,
        frequencyData: [],
        ...item,
      });
    }
  });
  return filterList;
}
export const fetchValue = (frequencyBand: any, frequencyBandList: any[]) => {
  const isFrequencyAvailable = frequencyBandList.filter(
    (o: any) => o?.frequencyBand.toLowerCase() == frequencyBand.toLowerCase()
  );
  if (isFrequencyAvailable.length > 0) {
    const findFrequencyIndex = (e: any) =>
      e.frequencyBand.toLowerCase() == frequencyBand.toLowerCase();

    const channelIndex = frequencyBandList.findIndex(findFrequencyIndex);
    return frequencyBandList[channelIndex]?.frequencyValue || 0;
  }
  return 0;
};
