import { Config, ExperienceArtifacts } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "https://account.staging.neuromore.com",
  serverUrl: "https://backend.staging.neuromore.com",
  registrationUrl:
    "https://account.staging.neuromore.com/#/purchase?state=start&productId=community",
  checkout: {
    recaptchaKey: "6Lf5RuMlAAAAALecI60W3dLPxSblVBOr8VlHYmNx",
    token: "ZmYxMjc2ZGEtYWE3My00MTIyLTgzOWMtM2UwNjAyN2E5MzFl",
    stripeApiKey: "pk_test_bS4tkuLEotc4jqLuWNBVkrA0",
  },
  branding: {
    id: "neuromore",
    helmet: {
      title: "neuromore User Portal",
      description: "neuromore account management",
    },
    logo: {
      alt: "Neuromore logo",
      link: "https://www.neuromore.com",
    },
    companyName: "neuromore",
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
  logoIcon: require("./assets/logo_icon.png"),
};

export default config;
