import { Config } from "../../types";
import {} from "./components";
import { messages } from "../../messages";
import { Role } from "../../../types";

const config: Config = {
  url: "https://app.supermind.us",
  serverUrl: "https://backend.app.supermind.us",
  registrationUrl: "",
  downloads: [],
  branding: {
    id: "supermind",
    helmet: {
      title: "supermind User Portal",
      description: "supermind account management",
    },
    logo: {
      alt: "supermind logo",
      link: "https://supermind.us",
    },
    companyName: "supermind",
  },
  sessions: {
    hideDataIfChannelNameStartsWith: "",
    hideMaxRenderSamplesSelection: false,
    hideSingleFileDownloadButtons: false,
    hideRenderedRecordedSamplesTable: false,
  },
  CRMUsers: {
    hideMembers: false,
  },
  userRoles: [
    Role.ClinicAdmin,
    Role.ClinicClinician,
    Role.ClinicOperator,
    Role.ClinicPatient,
  ],
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
  logoIcon: require("./assets/logo_icon.png"),
  generateAssessmentsEnabled: true,
  generateSessionReportsEnabled: true,
};

export default config;
