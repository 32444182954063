import { Config, ExperienceArtifacts } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "http://127.0.0.1:3000",
  serverUrl: "http://localhost:8080/neuromore-server",
  registrationUrl:
    "http://127.0.0.1:3000/#/purchase?state=start&productId=community",
  branding: {
    id: "neuromore",
    helmet: {
      title: "neuromore User Portal",
      description: "neuromore account management",
    },
    logo: {
      alt: "Neuromore logo",
      link: "https://www.neuromore.com",
    },
    companyName: "neuromore",
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
  logoIcon: require("./assets/logo_icon.png"),
};

export default config;
