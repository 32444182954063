import { Config } from "../../types";
import { messages } from "../../messages";
import { defaultUserRoles } from "../../constants";

const config: Config = {
  url: "https://starrbase.myneurva.com/",
  serverUrl: "https://backend.starrbase.myneurva.com",
  registrationUrl:
    "https://starrbase.myneurva.com/#/purchase?state=start&productId=community",
  downloads: [
    {
      label: messages.software,
      items: [
        {
          label: "neuromore Studio (macOS)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/macos/neuromoreInstaller.dmg",
        },
        {
          label: "neuromore Studio (Windows)",
          link:
            "https://neuromore-installer.s3.amazonaws.com/windows/neuromoreInstaller.exe",
        },
      ],
    },
  ],
  branding: {
    id: "starrbase",
    helmet: {
      title: "Starrbase",
      description: "Starrbase",
    },
    logo: {
      alt: "Starrbase logo",
      link: "https://myneurva.com",
    },
    companyName: "Starrbase",
  },
  CRMUsers: {
    hideMembers: true,
  },
  userRoles: defaultUserRoles,
  favicon: require("./assets/favicon.ico"),
  logo: require("./assets/logo-supplied.png"),
  logoIcon: require("./assets/logo_icon.png"),
};

export default config;
