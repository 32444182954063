import React from "react";

import LineGraph from "../../../components/LineGraph";
import {
  defaultHeight,
  defaultLineGraphProperties,
} from "../../../components/LineGraph/constants";
import { TimeLineDataItem } from "../types";
import Icon from "../../../styled/Icon";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { LineGraphLabel } from "../styled/ReportLayout";
import { chartOptions, datasetOptions } from "../constants";
import labels from "../../../utils/labels";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ExternalLink from "../../../styled/ExternalLink";
import Button from "../../../styled/Button";
import config from "config";

type Props = {
  data: TimeLineDataItem;
  graphHeight?: number;
};

const SessionChannel = ({ data, graphHeight }: Props) => (
  <>
    {!!(data.data.length > 0 && data.labels.length > 0) && (
      <>
        <LineGraph
          label=""
          labels={data.labels}
          data={data.data}
          height={graphHeight ?? defaultHeight}
          datasetsOptions={defaultLineGraphProperties}
          options={chartOptions}
        />
        <LineGraphLabel>{labels.sessions.timeInSeconds}</LineGraphLabel>
        <br />
      </>
    )}
    {!config.sessions?.hideSingleFileDownloadButtons && !!data.dataUrl && (
      <>
        <div>
          <ExternalLink href={data.dataUrl} target="_blank">
            <Button blue="true" withoutOffset>
              <Icon icon={faDownload} size="lg" />
              {labels.sessions.saveUMDFile}
            </Button>
          </ExternalLink>
        </div>
        <br />
      </>
    )}
    {!config.sessions?.hideRenderedRecordedSamplesTable && (
      <Paper variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{labels.sessions.rendered}</TableCell>
              <TableCell align="right">{labels.sessions.recorded}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {labels.sessions.samples}
              </TableCell>
              <TableCell align="right">{data.dataSamples}</TableCell>
              <TableCell align="right">{data.totalSamples}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {labels.sessions.sampleRate}
              </TableCell>
              <TableCell align="right">{data.dataSampleRate}</TableCell>
              <TableCell align="right">{data.sampleRate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    )}
  </>
);

export default SessionChannel;
