import { Config } from "./types";

import antConfig from "./configs/ant";
import starrbaseConfig from "./configs/starrbase";
import supermindConfig from "./configs/supermind";
import natusConfig from "./configs/natus";
import developmentConfig from "./configs/development";
import productionConfig from "./configs/production";
import stagingConfig from "./configs/staging";

const appConfig = (): Config => {
  let env;
  switch (window.location.host) {
    case "account.staging.neuromore.com":
      env = "staging";
      break;
    case "account.neuromore.com":
      env = "production";
      break;
    case "starrbase.myneurva.com":
      env = "starbase";
      break;
    case "account.eego-perform.com":
      env = "ant";
      break;
    case "app.supermind.us":
    case "account.app.supermind.us":
      env = "supermind";
      break;
    case "neurofeedback.natus-rad.com":
    case "account.neurofeedback.natus-rad.com":
      env = "natus";
      break;
    case "127.0.0.1:3000":
    case "localhost:3000":
    default:
      env = "development";
  }

  console.log(env);

  if (env === "production") {
    return productionConfig;
  }

  if (env === "staging") {
    return stagingConfig;
  }

  if (env === "ant") {
    return antConfig;
  }

  if (env === "starrbase") {
    return starrbaseConfig;
  }

  if (env === "supermind") {
    return supermindConfig;
  }

  if (env === "natus") {
    return natusConfig;
  }

  return developmentConfig;
};

export default appConfig();
