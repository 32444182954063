import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectListPagination } from "store/ducks/sessions/selectors";
import { MainContainer, TitleText, UserName } from "./styles";

type Props = {
  session: any;
};

const PatientDetail = ({ session }: Props) => {
  const [userName, setUserName] = useState<any>("");
  const [user, setUserData] = useState<any>("");
  const [provider, setProviderData] = useState<any>();

  const pagination = useSelector(selectListPagination);

  const getAge = (birthDate: any) =>
    Math.floor(
      (new Date().valueOf() - new Date(birthDate).getTime()) / 3.15576e10
    );

  const renderDetail = (title: string, value: any) => {
    if (!value) return;
    return (
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          marginTop: "10px",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            marginRight: "10px",
          }}
        >
          <TitleText>{title}</TitleText>
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <TitleText>{title == "Age" ? getAge(value) : value}</TitleText>
        </div>
      </div>
    );
  };
  useEffect(() => {
    try {
      if (session) {
        const objValue = Object.values(session);
        const sessionData: any = objValue[0] || [];
        setProviderData(sessionData[0]?.provider);
        setUserData(sessionData[0]?.user);
        console.log(sessionData[0]?.user);
        setUserName(
          sessionData[0]?.user?.firstname + " " + sessionData[0]?.user?.lastname
        );
      }
    } catch (e) {}
  }, [session]);

  return (
    <MainContainer
      style={{
        paddingRight: "2px",
      }}
    >
      <UserName>{userName}</UserName>
      {renderDetail("Age", user?.birthday)}
      {renderDetail("Handedness", user?.handedness)}
      {/* {renderDetail("Gender", user?.gender ? "Male" : "Female")} */}
      {renderDetail("Goal", user?.reasonForTherapy)}
      {renderDetail(
        "Number of sessions",
        pagination ? pagination?.totalElements || 0 : 0
      )}
    </MainContainer>
  );
};

export default PatientDetail;
