import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  padding-right: 9px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
`;
export const UserName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #000000;
  margin: 0px;
  margin-bottom: 4px;
`;

export const TitleText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #000000;
`;
export const ChannelNameContainer = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #009fe3;
  margin-right: 2px;
  cursor: pointer;
`;
export const ChannelNameText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #009fe3;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;
export const ChannelContainer = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const ChannelNameMainContainer = styled.div`
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
`;
