import styled from "styled-components";

export const MainContainer1 = styled.div`
  display: flex;
  background: #ffffff;
  flex-direction: row;
`;
export const QEEGText = styled.p`
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  font-style: normal;
  color: #000000;
  text-align: left;
  margin: 0px;
  margin-bottom: 4px;
`;

export const DateText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 12px;
  margin: 0px;
  text-align: left;
`;
export const GraphTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: -0.01em;
  color: #7091b6;
  margin-bottom: 15px;
  font-weight: 600;
  /* margin: auto; */
  text-align: center;
  margin-top: 6px;
`;
export const GraphContainer = styled.div`
  margin-right: 4px;
  margin-left: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const ScaleText = styled.p`
  font-style: normal;
  font-size: 6px;
  color: #7091b6;
  font-weight: 600;
  margin: 0px;
  margin-left: 2px;
  margin-top: -2px;
`;
export const ColumnContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 7px;
`;
export const ScaleTextContainer = styled.div`
  width: 43px;
  height: 40px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
`;
export const ScaleContainer = styled.div`
  width: 4px;
  height: 40px;
  flex-direction: column;
  display: flex;
`;
export const VerticalSeperator = styled.div`
  height: 1px;
  width: 1px;
  background-color: #000;
`;

export const VerticalSeperatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ScaleColourContainer = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
`;
export const HeadImage = styled.img`
  width: 60px;
  height: 65px;
  z-index: 4;
  margin-top: -42px;
  position: absolute;
`;
export const HeatMapContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
`;
export const FP1Container = styled.div`
  position: absolute;
  top: 2px;
  left: 21px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const FP2Container = styled.div`
  position: absolute;
  top: 2px;
  right: 21px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const F7Container = styled.div`
  position: absolute;
  top: 15px;
  left: 4px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const F3Container = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const FZContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 29px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const F4Container = styled.div`
  position: absolute;
  top: 16px;
  right: 15px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const F8Container = styled.div`
  position: absolute;
  top: 15px;
  right: 4px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const T3Container = styled.div`
  position: absolute;
  top: 30px;
  left: 0px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const C3Container = styled.div`
  position: absolute;
  top: 30px;
  left: 14px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const CZContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 29px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const C4Container = styled.div`
  position: absolute;
  top: 30px;
  right: 14px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const T4Container = styled.div`
  position: absolute;
  top: 30px;
  right: 0px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const T5Container = styled.div`
  position: absolute;
  bottom: 12px;
  left: 6px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const P3Container = styled.div`
  position: absolute;
  bottom: 13px;
  left: 17px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const PZContainer = styled.div`
  position: absolute;
  bottom: 14px;
  left: 29px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;

export const P4Container = styled.div`
  position: absolute;
  bottom: 13px;
  right: 17px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const T6Container = styled.div`
  position: absolute;
  bottom: 12px;
  right: 6px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;

export const O1Container = styled.div`
  position: absolute;
  bottom: 2px;
  left: 21px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;
export const O2Container = styled.div`
  position: absolute;
  bottom: 2px;
  right: 21px;
  border: 1px solid #000;
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  z-index: 99;
`;

export const EmptyContainer = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 10px;
  width: 100%;
`;
export const EmptyText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* identical to box height; or 24px */
  letter-spacing: -0.01em;
  color: #3c3c3b;
  text-align: center;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
