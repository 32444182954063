import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    width: "100px",
    border: "1px solid #000",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  valueContainer: {
    width: "100px",
    flexDirection: "row",
    display: "flex",
  },
  frequencyText: {
    fontSize: "13px",
    color: "black",
    fontWeight: 600,
    marginBottom: "1px",
  },
  frequencyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  horizontalSeperator: {
    width: "100px",
    height: "1px",
    border: "1px dashed #DFDFDF",
    marginTop: "2px",
    marginBottom: "2px",
  },
  seperator: {
    width: "100px",
    height: "1px",
    backgroundColor: "#000",
    marginTop: "2px",
  },
  dashedLine: {
    width: "1px",
    border: "1px dashed #DFDFDF",
  },
  itemContainer: {
    flexDirection: "column",
    display: "flex",
  },
  headerText: {
    fontSize: "14px",
    color: "#0A0B09",
    fontWeight: 600,
  },
  pageContainer: {
    position: "relative",
    marginTop: "25px",
    width: "75%",
    height: "70%",
  },
  marginTop: {
    marginTop: "65px",
    display: "flex",
  },
  leftContainer: {
    position: "absolute",
    top: "110px",
  },
  rightContainer: {
    position: "absolute",
    left: "450px",
    top: "110px",
  },
  centerContainer: {
    flexDirection: "row",
    display: "flex",
    position: "absolute",
    left: "118px",
    width: "320px",
    justifyContent: "space-between",
  },
  marginTop16: {
    marginTop: "16px",
    display: "flex",
  },
  marginTop174: {
    marginTop: "174px",
    display: "flex",
  },
  frequency: {
    fontWeight: 400,
    fontSize: "11px",
  },
  frequencyC: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  },
  userName: {
    // marginTop: "40px",
    marginBottom: "14px",
    // color: "#000000",
    fontSize: "19px",
    fontWeight: 500,
    color: "#009fe3",
    // fontFamily:'inherit-medium'
  },
  date: {
    color: "#000000",
    fontSize: "11px",
    // fontFamily:'inherit-light'
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  frequencyMainContainer: {
    border: "1px solid #000",
    borderRadius: "4px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "128px",
    flexDirection: "row",
    marginTop: "10px",
  },
  frequencyInnerContainer: {
    flexDirection: "row",
    display: "flex",
    width: "110px",
    marginTop: "10px",
    marginBottom: "10px",
  },
});

export default styles;
