import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  padding-right: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  min-width: 190px;
`;
export const UserName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 10px;
`;

export const TitleText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #000000;
`;
