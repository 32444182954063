import React from "react";
import { FormControlLabel } from "@material-ui/core";
import MatCheckbox from "./Form/styled/Checkbox";

interface Props {
  label?: React.ReactNode;
  getValue: () => boolean;
  setValue: (value: boolean) => void;
}

const Checkbox = ({ label, getValue, setValue }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<MatCheckbox checked={getValue()} onChange={handleChange} />}
      label={label}
    />
  );
};

export default Checkbox;
