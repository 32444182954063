import config from "config";
import { Role } from "types";

export const genderOptions = [
  { label: "Male", value: "true" },
  { label: "Female", value: "false" },
];
export const handednessOptions = [
  { label: "Left", value: "Left" },
  { label: "Right", value: "Right" },
];

export const roleOptions = config.userRoles.map((role) => ({
  label: role,
  value: role,
}));

export const rolesMap: Record<Role, number> = {
  [Role.Admin]: 100,
  [Role.AppSubscription]: 89,
  [Role.BiofeedbackProvider]: 305,
  [Role.BiofeedbackUser]: 306,
  [Role.ClinicAdmin]: 3001,
  [Role.ClinicClinician]: 3002,
  [Role.ClinicOperator]: 3003,
  [Role.ClinicPatient]: 3004,
  [Role.Community]: 102,
  [Role.Professional]: 103,
  [Role.ResellerAdmin]: 3000,
  [Role.Ultimate]: 101,
};
