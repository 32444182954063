import React, { useEffect, useState } from "react";
import {
  fetchValue,
  formateDataForQEEGGraph,
} from "../../QEEGGraph/QEEGGraphUtils";
import { MainContainer } from "../PatientDetail/styles";
import {
  DateText,
  C3Container,
  C4Container,
  ColumnContainer,
  CZContainer,
  F3Container,
  F4Container,
  F7Container,
  F8Container,
  FP1Container,
  FP2Container,
  FZContainer,
  GraphContainer,
  GraphTitle,
  HeadImage,
  HeatMapContainer,
  MainContainer1,
  O1Container,
  O2Container,
  P3Container,
  P4Container,
  PZContainer,
  QEEGText,
  ScaleColourContainer,
  ScaleContainer,
  ScaleText,
  ScaleTextContainer,
  T3Container,
  T4Container,
  T5Container,
  T6Container,
  VerticalSeperator,
  VerticalSeperatorContainer,
  EmptyContainer,
  EmptyText,
  LoaderContainer,
} from "./styles";
import Head from "../../../../../images/Head.png";
import h337 from "heatmap.js";
import { addTimezoneOffset, getDate } from "utils/datetime";
import moment from "moment";
import CircularProgress from "components/Loader/styled/CircularProgress";

const QEEGData = (props: any) => {
  const [qeegList1, setQEEGList1] = useState<any>([]);
  const [qeegList2, setQEEGList2] = useState<any>([]);
  const [mainQEEGList, setMainQEEGList] = useState<any>([]);

  useEffect(() => {
    setMainQEEGList(props.qeegList);
  }, [props.qeegList]);

  useEffect(() => {
    if (mainQEEGList && mainQEEGList.length > 0) {
      calculateData(mainQEEGList[0], "1");
      if (mainQEEGList.length > 1) {
        calculateData(mainQEEGList[1], "2");
      } else {
        setQEEGList2([]);
      }
    } else {
      setQEEGList1([]);
      setQEEGList2([]);
    }
  }, [mainQEEGList]);
  const calculateData = async (item: any, listNumber: any) => {
    try {
      const formattedList: any = await formateDataForQEEGGraph([item]);
      if (listNumber == "1") {
        setQEEGList1([]);
        setQEEGList1(formattedList);
      } else if (listNumber == "2") {
        setQEEGList2([]);
        setQEEGList2(formattedList);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (qeegList1) {
      setHeatMapData(qeegList1, "1");
    }
  }, [qeegList1]);
  useEffect(() => {
    if (qeegList2) {
      setHeatMapData(qeegList2, "2");
    }
  }, [qeegList2]);
  const setHeatMapData = (list: any, number: any) => {
    list.map((item: any, index: any) => {
      if (document.getElementById(`heatmap${number}-${index}`)) {
        if (item?.channelName == "High Gamma") return;

        var heatmap = h337.create({
          blur: 0.5,
          container: document.getElementById(`heatmap${number}-${index}`)!,
          gradient: {
            "0.00": "rgb(0,0,255)",
            "0.10": "rgb(0,0,255)",
            "0.15": "rgb(0,0,255)",
            "0.37": "#0df3f8",
            "0.50": "rgb(0,255,0)",
            "0.75": "rgb(255,255,0)",
            "1.00": "rgb(255,0,0)",
          },
        });

        let pointss = [
          {
            radius: 13,
            x: 22,
            y: 4,
            value: fetchValue("FP1", item?.frequencyData),
          },
          {
            radius: 13,
            x: 38,
            y: 4,
            value: fetchValue("FP2", item?.frequencyData),
          },
          {
            radius: 13,
            x: 5,
            y: 16,
            value: fetchValue("F7", item?.frequencyData), //F7
          },
          {
            radius: 11,
            x: 18,
            y: 18,
            value: fetchValue("F3", item?.frequencyData),
          },
          {
            radius: 12,
            x: 31,
            y: 18,
            value: fetchValue("FZ", item?.frequencyData),
          },
          {
            radius: 11,
            x: 44,
            y: 18,
            value: fetchValue("F4", item?.frequencyData),
          },
          {
            radius: 11,
            x: 55,
            y: 17,
            value: fetchValue("F8", item?.frequencyData), //f8
          },
          {
            radius: 12,
            x: 0,
            y: 32,
            value: fetchValue("T3", item?.frequencyData),
          },
          {
            radius: 11,
            x: 16,
            y: 32,
            value: fetchValue("C3", item?.frequencyData),
          },
          {
            radius: 11,
            x: 30,
            y: 32,
            value: fetchValue("CZ", item?.frequencyData),
          },
          {
            radius: 12,
            x: 45,
            y: 32,
            value: fetchValue("C4", item?.frequencyData),
          },
          {
            radius: 11,
            x: 59,
            y: 31,
            value: fetchValue("T4", item?.frequencyData),
          },
          {
            radius: 12,
            x: 6,
            y: 47,
            value: fetchValue("T5", item?.frequencyData), //T5
          },
          {
            radius: 10,
            x: 18,
            y: 45,
            value: fetchValue("P3", item?.frequencyData),
          },
          {
            radius: 10,
            x: 30,
            y: 45,
            value: fetchValue("PZ", item?.frequencyData),
          },
          {
            radius: 10,
            x: 42,
            y: 45,
            value: fetchValue("P4", item?.frequencyData),
          },
          {
            radius: 11,
            x: 53,
            y: 46,
            value: fetchValue("T6", item?.frequencyData), //T6
          },
          {
            radius: 12,
            x: 23,
            y: 57,
            value: fetchValue("O1", item?.frequencyData),
          },
          {
            radius: 12,
            x: 38,
            y: 57,
            value: fetchValue("O2", item?.frequencyData),
          },
        ];

        // let pointss = [
        //   {
        //     x: 21,
        //     y: 2,
        //     value: fetchValue("FP1", item?.frequencyData),
        //   },
        //   {
        //     x: 41,
        //     y: 2,
        //     value: fetchValue("FP2", item?.frequencyData),
        //   },
        //   {
        //     x: 4,
        //     y: 15,
        //     value: fetchValue("F7", item?.frequencyData),
        //   },
        //   {
        //     x: 18,
        //     y: 18,
        //     value: fetchValue("F3", item?.frequencyData),
        //   },
        //   {
        //     x: 29,
        //     y: 18,
        //     value: fetchValue("FZ", item?.frequencyData),
        //   },
        //   {
        //     x: 44,
        //     y: 18,
        //     value: fetchValue("F4", item?.frequencyData),
        //   },
        //   {
        //     x: 60,
        //     y: 15,
        //     value: fetchValue("F8", item?.frequencyData),
        //   },
        //   {
        //     x: 0,
        //     y: 30,
        //     value: fetchValue("T3", item?.frequencyData),
        //   },
        //   {
        //     x: 15,
        //     y: 32,
        //     value: fetchValue("C3", item?.frequencyData),
        //   },
        //   {
        //     x: 30,
        //     y: 30,
        //     value: fetchValue("CZ", item?.frequencyData),
        //   },
        //   {
        //     x: 44,
        //     y: 31,
        //     value: fetchValue("C4", item?.frequencyData),
        //   },
        //   {
        //     x: 60,
        //     y: 31,
        //     value: fetchValue("T4", item?.frequencyData),
        //   },
        //   {
        //     x: 6,
        //     y: 46,
        //     value: fetchValue("T5", item?.frequencyData),
        //   },
        //   {
        //     x: 17,
        //     y: 45,
        //     value: fetchValue("P3", item?.frequencyData),
        //   },
        //   {
        //     x: 29,
        //     y: 45,
        //     value: fetchValue("PZ", item?.frequencyData),
        //   },
        //   {
        //     x: 42,
        //     y: 45,
        //     value: fetchValue("P4", item?.frequencyData),
        //   },
        //   {
        //     x: 55,
        //     y: 46,
        //     value: fetchValue("T6", item?.frequencyData),
        //   },
        //   {
        //     x: 23,
        //     y: 57,
        //     value: fetchValue("O1", item?.frequencyData),
        //   },
        //   {
        //     x: 38,
        //     y: 57,
        //     value: fetchValue("O2", item?.frequencyData),
        //   },
        // ];
        heatmap.setData({
          max: item?.frequencyData.length > 0 ? item?.highFrequency : 50,
          data: pointss,
          min: 0,
          // min: item?.frequencyData.length > 0 ? item?.lowFrequency : 0,
        });
      }
    });
  };
  const colors = [
    "#0a16d5",
    "#005cf1",
    "#00a8f4",
    "#0df3f8",
    "#12f298",
    "#0ef502",
    "#08f600",
    "#07f200",
    "#79ec00",
    "#f6f80a",
    "#ff9800",
    "#fe3f0a",
    "#ff0000",
  ];
  const renderScale = () => {
    colors.reverse();
    return (
      <ColumnContainer>
        <ScaleContainer>
          {colors.map((item: any, index: number) => {
            return (
              <ScaleColourContainer>
                <div
                  style={{
                    flex: 1,
                    width: "3px",
                    backgroundColor: item,
                  }}
                />
                <VerticalSeperatorContainer>
                  <VerticalSeperator />
                  {index == colors.length - 1 ? <VerticalSeperator /> : <></>}
                </VerticalSeperatorContainer>
              </ScaleColourContainer>
            );
          })}
        </ScaleContainer>
        <ScaleTextContainer>
          <ScaleText>{"Abnormally High"}</ScaleText>
          <ScaleText>{"Normal"}</ScaleText>
          <ScaleText>{"Low"}</ScaleText>
        </ScaleTextContainer>
      </ColumnContainer>
    );
  };
  const getFormattedDate = (session: any) => {
    try {
      const date = moment(
        getDate(addTimezoneOffset(session?.startDateTime))
      ).format("MMMM DD, YYYY");
      return date;
    } catch (e) {}
  };
  const renderLoader = () => {
    return props.isLoading ? (
      <LoaderContainer>
        <CircularProgress size={20} />
      </LoaderContainer>
    ) : null;
  };
  const renderGraph = (list: any, number: any) => {
    if (list.length > 0) {
      return (
        <div>
          <DateText>
            {getFormattedDate(
              number == "2" ? mainQEEGList[1] : mainQEEGList[0]
            )}
          </DateText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
            }}
          >
            {renderScale()}

            {list.map((item: any, index: number) => {
              if (item?.channelName == "High Gamma") return;
              return (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <MainContainer1>
                    <GraphContainer>
                      <HeadImage src={Head} alt={""} />
                      <HeatMapContainer id={`heatmap${number}-${index}`}>
                        <FP1Container />
                        <FP2Container />
                        {/* start second row */}

                        <F7Container />
                        <F3Container />
                        <FZContainer />
                        <F4Container />
                        <F8Container />
                        {/* end second row */}

                        {/* start third row */}
                        <T3Container />
                        <C3Container />
                        <CZContainer />
                        <C4Container />
                        <T4Container />
                        {/* end third row */}
                        {/* start fourth row */}
                        <T5Container />
                        <P3Container />
                        <PZContainer />
                        <P4Container />
                        <T6Container />
                        {/* end fourth row */}
                        <O1Container />
                        <O2Container />
                      </HeatMapContainer>
                      <GraphTitle>{item?.channelName}</GraphTitle>
                    </GraphContainer>
                  </MainContainer1>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <EmptyContainer>
        <EmptyText>Please select a QEEG session to compare</EmptyText>
      </EmptyContainer>
    );
  };
  return (
    <MainContainer
      style={{
        marginLeft: "10px",
        minWidth: "490px",
        position: "relative",
      }}
    >
      <QEEGText>QEEG</QEEGText>
      {renderLoader()}

      {renderGraph(qeegList1, "1")}
      {renderGraph(qeegList2, "2")}
    </MainContainer>
  );
};

export default QEEGData;
