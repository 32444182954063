import React, { useEffect, useState } from "react";
import { EmptyContainer, EmptyText } from "../QEEGData/styles";
import {
  ChannelContainer,
  ChannelNameContainer,
  ChannelNameMainContainer,
  ChannelNameText,
  MainContainer,
  TitleText,
  UserName,
} from "./styles";
import RightArrow from "../../../../../images/ic_right_arrow.png";
import { formateDataForProgressChart } from "./ProgressReportUtils";
import * as echarts from "echarts";

type Props = {
  sessionList: any[];
};

const ProgressReport = ({ sessionList }: Props) => {
  const [selectedChannel, setSelectedChannel] = useState<any>(0);
  const [channelList, setChannelList] = useState<any>([]);
  const [sessiondata, setSessionData] = useState<any>([]);
  const [lastVisibleChannelIndex, setLastVisibleChannelIndex] = useState<any>();

  useEffect(() => {
    if (sessionList.length > 0) {
      formateSessionList(sessionList);
    }
  }, [sessionList]);

  const initChart = (frequency: any, dateRecord: any) => {
    const chartDom = document.getElementById("line-chart")!;
    const myChart = echarts.init(chartDom);
    const option = {
      textStyle: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 14,
        color: "#333333",
      },
      axisPointer: {
        // show: 'auto'
      },
      tooltip: {
        // show: true
      },
      xAxis: {
        axisLine: {
          show: true,
          lineStyle: {
            color: "#CCCCCC",
            type: "dashed",
          },
        },
        axisLabel: {
          rotate: 45,
          fontSize: 10,
          hideOverlap: true,
        },
        axisTick: {
          show: false,
        },
        type: "category",
        data: dateRecord,
      },
      color: ["#0E9CFF"],
      grid: {
        show: false,
        borderWidth: 1,
        borderColor: "#ECF2F7",
        top: 30,
        right: 3,
      },
      yAxis: [
        {
          name: "",
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#CCCCCC",
              type: "dashed",
            },
          },
          axisLabel: {
            fontSize: 10,
          },
        },
      ],
      dataZoom: [
        {
          startValue: dateRecord[0],
          top: "175px",
          height: 26,
        },
        {
          type: "inside",
        },
      ],
      series: [
        {
          data: frequency,
          lineStyle: {
            width: 2,
            borderWidth: 1,
            borderColor: "#F2FCF6",
            borderRadius: 100,
          },
          type: "line",
          smooth: true,
        },
      ],
    };

    option && myChart.setOption(option);
  };
  const formateSessionList = async (list: any) => {
    let l = await formateDataForProgressChart(list);

    if (l && l.length > 0) {
      const channels: any[] = [];
      l.map((item: any) => {
        channels.push(item.channelName);
      });
      const obj = getSelectedData(l, 0);
      setSessionData(l);
      setChannelList(channels);
      setSelectedChannel(l[0]?.channelName);
      initChart(obj?.frequency, obj?.dates);
    }
  };
  const getSelectedData = (list: any, index: number) => {
    const frequency: any[] = [];
    const dates: any[] = [];
    const frequencyData = list[index]?.frequencyData;
    frequencyData.map((item1: any) => {
      dates.push(item1.frequencyBand);
      frequency.push(item1.frequencyValue.toFixed(2));
    });
    return {
      dates,
      frequency,
    };
  };
  const onChannelNameSelect = (item?: any) => {
    setSelectedChannel(item);
    const filterList = sessiondata.filter((o: any) => o.channelName == item);
    if (filterList.length > 0) {
      const obj = getSelectedData(filterList, 0);
      initChart(obj?.frequency, obj?.dates);
    }
  };
  const renderChannel = () => {
    let channels: any = [];
    if (lastVisibleChannelIndex && lastVisibleChannelIndex >= 0) {
      const l = channelList.slice(
        lastVisibleChannelIndex + 1,
        lastVisibleChannelIndex + 4
      );
      if (l.length > 0) {
        channels = l;
      }
    } else {
      channels = channelList.slice(0, 3);
    }
    return (
      <ChannelContainer>
        <img
          src={RightArrow}
          style={{
            transform: `rotate(180deg)`,
            width: "30px",
            height: "30px",
            cursor: channels[0] != channelList[0] ? "pointer" : "not-allowed",
          }}
          alt={""}
          onClick={() =>
            setLastVisibleChannelIndex(
              channels[0] != channelList[0] ? lastVisibleChannelIndex - 3 : null
            )
          }
        />
        <ChannelNameMainContainer>
          {channels.map((item: any) => {
            return (
              <ChannelNameContainer
                onClick={() => {
                  onChannelNameSelect(item);
                }}
                style={{
                  backgroundColor: item == selectedChannel ? "#009FE3" : "#fff",
                }}
              >
                <ChannelNameText
                  style={{
                    color: item == selectedChannel ? "#FFF" : "#009FE3",
                  }}
                >
                  {item}
                </ChannelNameText>
              </ChannelNameContainer>
            );
          })}
        </ChannelNameMainContainer>
        <img
          src={RightArrow}
          style={{
            width: "30px",
            height: "30px",
            cursor:
              channels[channels.length - 1] !=
              channelList[channelList.length - 1]
                ? "pointer"
                : "not-allowed",
          }}
          alt={""}
          onClick={() => {
            if (
              channels[channels.length - 1] !=
              channelList[channelList.length - 1]
            ) {
              setLastVisibleChannelIndex(
                lastVisibleChannelIndex ? lastVisibleChannelIndex + 3 : 2
              );
            }
          }}
        />
      </ChannelContainer>
    );
  };
  const renderLineChart = () => {
    return (
      <div
        style={{
          height: "260px",
          width: "100%",
        }}
      >
        <div
          id="line-chart"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </div>
    );
  };
  return (
    <MainContainer
      style={{
        flex: 1,
        marginLeft: "10px",
        paddingRight: sessionList.length > 0 ? "9px" : "20px",
      }}
    >
      <UserName>Progress</UserName>
      {sessionList.length > 0 ? (
        <div
          style={{
            flex: 1,
            width: "100%",
            marginTop: "10px",
            height: "300px",
          }}
        >
          {renderChannel()}
          {renderLineChart()}
        </div>
      ) : (
        <EmptyContainer
          style={{
            height: "300px",
          }}
        >
          <EmptyText>
            Please select a few sessions to analyze inter-session progress
          </EmptyText>
        </EmptyContainer>
      )}
    </MainContainer>
  );
};

export default ProgressReport;
