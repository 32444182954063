import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleText: {
    width: "100px",
    textAlign: "right",
    marginRight: "10px",
    fontSize: "15px",
    color: "#000",
  },
  userDetailContainer: {
    flexDirection: "row",
    display: "flex",
  },
  valueText: {
    fontSize: "15px",
    color: "#000",
  },
  userDetailBorder: {
    border: "1px solid #000",
    width: "94%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  sessionReportFor: {
    fontSize: "15px",
    color: "#000",
    marginTop: "10px",
    marginBottom: "10px",
  },
  seperator: {
    width: "100%",
    height: "1px",
    backgroundColor: "#000",
  },
  tableHeaderContainer: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
  },
  cellContainer: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
  },
  headerText: {
    marginTop: "10px",
    marginBottom: "10px",
    flex: 1,
    textAlign: "center",
    fontSize: "13px",
  },
  verticleSeperator: {
    width: "1px",
    backgroundColor: "black",
  },
  horizontalSeperator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#000",
  },
  rowText: {
    marginTop: "10px",
    marginBottom: "10px",
    flex: 1,
    marginLeft: "0px",
    marginRight: "0px",
    textAlign: "left",
    fontSize: "9px",
    fontWeight: 400,
    color: "#15171B",
  },
  footerContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    bottom: 15,
    left: 0,
    right: 0,
    width: "100%",
  },
  footerInnerContainer: {
    flexDirection: "row",
    display: "flex",
    width: "94%%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerText: {
    fontSize: 8,
    color: "#000",
  },
  headerContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 15,
    left: 0,
    right: 0,
    width: "100%",
  },
  userName: {
    marginBottom: "10px",
    fontSize: "22px",
    fontWeight: 500,
    color: "#009fe3",
  },
  date: {
    color: "#000000",
    fontSize: "12px",
  },
  dateContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  chartImage: {
    // width: "500px",
    // height: "320px",
    width: "690px",
    height: "480px",
    objectFit: "stretch",
    transform: "rotate(-90deg)",
  },
  chartContainer: {
    marginTop: "150px",
  },
  pdfLogoContainer: {
    width: "40px",
    height: "40px",
    marginTop: "-20px",
  },
  userContainer: {
    width: "94%",
    marginTop: "25px",
  },
});

export default styles;
