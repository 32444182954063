export function formateDataForAssessmentImage(data?: any) {
  let newList: any = [];
  let channelList: any = [];

  // aggregate multiple channel list
  data.map((item: any) => {
    const channels = item?.channels;
    if (channels) {
      channelList.push(...item?.channels);
    }
  });
  channelList.map((item: any) => {
    const dataList = item?.data;
    if (dataList.length > 0) {
      let value = 0;
      dataList.map((dataObj: any) => {
        if (dataObj && dataObj.length > 1) {
          const frequencyValue = dataObj[1];
          value = value + frequencyValue;
        }
      });
      let newObj: any = {};
      const channelName = item?.channelName;
      var replaced = channelName.replace(/\s/g, ""); // replace extra space
      const channelNameArray = replaced.split("-"); // split string with - character

      if (channelNameArray && channelNameArray.length >= 3) {
        const channelName = channelNameArray[2]; // get channel name
        const frequencyBand = channelNameArray[0] + "-" + channelNameArray[1]; // get frequency range
        const isChannelAvailable = newList.filter(
          (o: any) => o.channelName == channelName
        ); // check if channel data is already available in list
        if (isChannelAvailable.length > 0) {
          const findChannelIndex = (e: any) => e.channelName == channelName;
          let frequencyObj: any = {};
          const channelIndex = newList.findIndex(findChannelIndex); //find available channel index

          const o: any = newList[channelIndex];
          const data = o.frequencyData;

          const findFrequencyBandIndex = (e: any) =>
            e.frequencyBand == frequencyBand;

          const frequencyBandIndex = data.findIndex(findFrequencyBandIndex); //find available frequency range
          if (frequencyBandIndex !== -1) {
            const frequencyRange = data[frequencyBandIndex];
            if (frequencyRange) {
              const newAvg = value / dataList.length; // update frequency value
              frequencyRange.frequencyValue =
                (frequencyRange?.frequencyValue + newAvg) / 2;
              data[frequencyBandIndex] = frequencyRange;
              o.frequencyData = data;
            }
          } else {
            frequencyObj.frequencyBand = frequencyBand; //update frequency data
            frequencyObj.frequencyValue = value / dataList.length; // update frequency value
            data.push(frequencyObj);
            o.frequencyData = data;
          }
        } else {
          let frequencyObj: any = {};
          let frequencyData: any = [];
          newObj.channelName = channelName;
          frequencyObj.frequencyBand = frequencyBand;
          frequencyObj.frequencyValue = value / dataList.length;
          frequencyData.push(frequencyObj);
          newObj.frequencyData = frequencyData;
          newList.push(newObj);
        }
      }
    }
  });
  return newList;
}
